.contact {
  padding: 4rem 0rem 4rem;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
  0 8px 10px -6px rgb(0 0 0 / 0.1);

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 4rem;
  }

  .left {
    flex: 1;
    max-width: 30rem;
    margin: 0 2rem;

    .info {
      padding-bottom: 1rem;
      
      h2 {
        text-align: center;
        font-size: 18px;
        font-style: italic;
        font-weight: normal;
        padding-bottom: 1rem;
      }

      p {
        font-size: 16px;
      }
    }
  }

  .right {
    flex: 1;
    max-width: 30rem;
    margin: 0 2rem;

    .form {
      display: flex;
      flex-direction: column;
      gap: 10px;

      input,
      textarea {
        font-family: "Rajdhani";
        font-size: 20px;
        font-weight: 500;
        color: #777;
        padding: 8px;
        border: 2px solid;
        border-radius: 10px;
      }

      .message {
        height: 100px;
      }

      button {
        font-family: "Rajdhani";
        font-weight: 500;
        font-size: 20px;
        background-color: #fff;
        color: #777;
        padding: 6px 30px;
        border: 2px solid;
        border-radius: 10px;
        width: 8rem;
        cursor: pointer;
      }

      button:hover {
        background-color: #856b89;
        border-color: #856b89;
        color: #fff;
        transition: ease-in 0.2s;
        transform: scale(1.05);
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .contact {
    padding: 2rem 0;

    .wrapper {
      padding-top: 0;

    }

    h2 {
      font-size: 22px;
    }
  }
}
