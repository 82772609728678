.about {
  padding: 4rem 0 2rem;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
  0 8px 10px -6px rgb(0 0 0 / 0.1);

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }

  p {
    padding: 0 2rem;
    font-size: 16px;
    text-align: center;
    line-height: 28px;
    margin: 0 20rem;
    color: #777;
  }

  img {
    width: 350px;
    margin: 0 auto;
    padding: 1rem;
  }
}

@media screen and (max-width: 950px) {
  .about {
    padding-top: 2rem;

    p {
      margin: 2rem auto;
    }

    img {
      width: 300px;
    }

  }
}
