.footer {
  width: 100%;
  background-color: #eee;

  .social-info {
    padding: 2rem 2rem 1rem;
    text-align: center;
    color: #777;
  }

  .social {
    .links {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4rem;

      .icon {
        display: flex;
        justify-content: center;
        font-size: 3rem;
        color: #856b89;
      }

      .item:hover {
        cursor: pointer;
        color: #856b89;
        transition: 0.5s ease;
        animation: shake 2s ease infinite;
      }
    }
  }

  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
    gap: 10px;

    .title {
      font-weight: 600;
      color: #008080;
    }

    span {
      font-weight: 600;
      color: #008080;
    }
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}
