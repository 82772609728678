.navbar {
  position: fixed;
  height: 10rem;
  width: 100%;
  background-color: #eee;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  z-index: 1;

  .wrapper {
    padding: 10px 10rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item {
      display: flex;
      align-items: center;
      font-size: 18px;

      .link {
        font-weight: 600;
        color: #008080;
      }

      .link:hover {
        color: #856b89;
        border-bottom: 1px solid;
        border-color: #856b89;
        transition: 0.5s ease;
      }
      
    }

    .left {
      display: flex;
      text-align: center;
      letter-spacing: 2px;
    }

    .logo {
      font-family: Megrim;
      font-size: 20px;
      line-height: 40px;
      // color: #008080;
      background: -webkit-linear-gradient(360deg, #008080, #856b89);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    
    .logo:hover {
      color: #856b89;
      animation: gelatine 0.5s;
    }

    @keyframes gelatine {
      from,
      to {
        transform: scale(1, 1);
      }
      25% {
        transform: scale(0.9, 1.1);
      }
      50% {
        transform: scale(1.1, 0.9);
      }
      75% {
        transform: scale(0.95, 1.05);
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 4rem;
    }

    .smallScreen {
      display: none;
    }

    .open {
      cursor: pointer;
      color: #008080;
    }

    .overlay {
      padding-top: 200px;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: #ddd;
      opacity: 95%;
      transition: 0.5s ease;

      z-index: 1000;

      .item {
        font-family: Dynalight;
        font-size: 2rem;
        color: #856b89;
        margin: 2rem;
        display: flex;
        justify-content: center;
      }
    }

    .close {
      color: #008080;
      cursor: pointer;
      position: absolute;
      top: 40px;
      right: 30px;
    }

    .close:hover {
      color: #856b89;
    }

    @media screen and (max-width: 950px) {
      .logo {
        font-size: 12px;
        line-height: 20px;
      }
      .right {
        display: none;
      }
      .smallScreen {
        display: flex;
      }
    }
  }

  @media screen and (max-width: 950px) {
    .wrapper {
      padding: 0px 20px;
      height: 5rem;
    }
  }
}

@media screen and (max-width: 950px) {
  .navbar {
    height: 5rem;
  }
}

.modal-info-wrapper {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 200px;
}

.modal-info-wrapper h1 {
  font-size: 40px;
  font-family: Dynalight;
  font-weight: 400;
  color: white;
  text-align: center;
}

.modal-close-button {
  background-color: transparent;
  color: white;
  letter-spacing: 0.04em;
  font-size: 16px;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  border: 1px solid;
  border-color: white;
  outline: none;
  cursor: pointer;
}

.modal-close-button:hover {
  background-color: #856b89;
  opacity: 50%;
  transition: 0.3s ease;
}