.gallery {
  padding: 10rem 0;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);

  .wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .img {
    width: 350px;
    padding: 10px;
  }

  .img-wrapper {
    position: relative;
    margin: 10px;
    border: 1px solid;
    border-color: #ddd;
    box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.4);
  }

  .img {
    display: flex;
  }
  .img-info {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10%;

    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;

    color: #fff;
    background-color: rgba(133, 107, 137, 0.8);
    visibility: hidden;
    opacity: 0;

    transition: opacity 0.2s, visibility 0.2s;

    .top,
    .bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title {
      margin: 0;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }

    .desc,
    .year,
    .price,
    .size {
      text-align: center;
      margin: 0;
    }

    .price {
      padding-top: 6px;
      font-size: 20px;
    }

    .sold {
      color: red;
      font-size: 22px;
      font-weight: 600;
      margin: 0;
    }

    .forsale {
      color: #66ff00;
      font-size: 22px;
      font-weight: 600;
      margin: 0;
    }

    .fz-18 {
      font-size: 18px;
    }

    .pt-10 {
      padding-top: 10px;
    }

    .strike {
      text-decoration: line-through;
    }
  }

  .img-wrapper:hover .img-info {
    visibility: visible;
    opacity: 1;
  }

  .col {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    .img {
      width: 90px;
      padding: 4px;
    }

    .img-wrapper {
      margin: 4px;
    }

    .img-info {
      gap: 10%;

      .price,
      .size,
      .year {
        margin: 0;
        font-size: 6px;
        text-align: center;
      }

      .title {
        font-size: 8px;
      }

      .desc {
        text-align: center;
        margin: 0;
        font-size: 7px;
      }

      .sold {
        font-size: 10px;
      }

      .forsale {
        font-size: 10px;
      }

      .fz-6 {
        font-size: 6px;
      }

      .fz-4 {
        font-size: 4px;
      }

      .pt-2 {
        padding-top: 2px;
      }
    }
  }
  .gallery {
    flex-wrap: wrap;
    padding: 5rem 0;
  }
}
