@import url("https://fonts.googleapis.com/css2?family=Akronim&family=Arizonia&&family=Cinzel+Decorative:wght@400;700&family=Dynalight&family=Fasthand&family=Fredericka+the+Great&family=Megrim&family=Montserrat:wght@400;500;600;700&family=Open+Sans&family=Rubik+Dirt&family=Tilt+Prism&family=Yatra+One&family=Zen+Tokyo+Zoo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600&display=swap");

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

* {
  scroll-behavior: smooth;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
