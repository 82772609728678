.header {
  display: flex;
  justify-content: center;

  h1 {
    font-weight: 400;
    font-style: italic;
    left: 0;
    color: #777;
    font-size: 40px;
    line-height: 20px;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 950px) {
  .header {
    h1 {
      font-size: 30px;
    }
  }
}
